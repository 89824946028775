//routes/index.js
import React, {useContext, useEffect, useState} from 'react';
import { NavLink, BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Chat from '../Chat/Chat';
import Project from '../Project/Project';
import Home from "../Home/Home";
import ContextArtifact from '../ContextArtifact/ContextArtifact';
import ChatHistory from '../ChatHistory/ChatHistory'
import Ranking from '../Ranking/Ranking'
// import { getApi } from '../../services/apiService';

import SystemMessage from '../SystemMessage/SystemMessage';
import {setCurrentChatId, setShowNav, setIsMobile, setCurrentProject, setResizeDetected} from "../../redux/actions";


import styles from './AppRouter.module.css'
import {AppContext} from "../../redux/AppContext";
import { useMsal, useAccount } from "@azure/msal-react";
import Profile from "../Profile/Profile";
import {ThemeContext} from "../../redux/ThemeContext";
import MaxModal from "../MaxModal/MaxModal";
import ContextModal from "../ContextModal/ContextModal";
import SystemMessageEditorPage from "../SystemMessageEditorPage/SystemMessageEditorPage";
import {useApi} from "../../hooks/useApi";
import {FaAngleDown, FaEdit} from "react-icons/fa";
import {FaPen} from "react-icons/fa6";
import {GoTriangleDown} from "react-icons/go";
// import Ranking from "../Ranking/Ranking";

function AppRouter() {
    const { state, dispatch } = useContext(AppContext);
    const { projectList, currentProject, showNav, isMobile, balance } = state;
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const navigate = useNavigate();
    const { theme, switchTheme } = useContext(ThemeContext);
    // const [balance, setBalance] = useState(null);

    const api = useApi();

    useEffect(() => {
        const mediaQueryList = window.matchMedia("(max-width: 768px)");
        const documentChangeHandler = () => {
            console.log( 'window size Modified! match = ', mediaQueryList.matches)
            dispatch(setShowNav(!mediaQueryList.matches));
            dispatch(setIsMobile(mediaQueryList.matches));
        }

        // Add listener
        mediaQueryList.addEventListener("change", documentChangeHandler);

        // Cleanup function
        return () => {
            // Remove listener
            mediaQueryList.removeEventListener("change", documentChangeHandler);
        }
    }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount


    useEffect(() => {
        const handleResize = () => {
            // Get the current window dimensions
            const width = window.innerWidth;
            const height = window.innerHeight;

            // Determine if the current width qualifies as mobile
            const isMobile = width < 768;

            // Dispatch the new state based on the window size
            // dispatch(setShowNav(!isMobile));
            // dispatch(setIsMobile(isMobile));
            dispatch(setResizeDetected({ width, height, isMobile }));
            // console.log('Window size modified!', { width, height, isMobile });
        };

        // Add the resize event listener
        window.addEventListener('resize', handleResize);

        // Optionally, trigger the handler on mount to initialize the state properly
        handleResize();

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [dispatch]);



    //TODO: persistir current project
    // useEffect(() => {
    //     localStorage.setItem('workspace', currentProject);
    // }, [currentProject]);

    // useEffect(()=> {
    //     loadBalance();
    // }, [account])
    //
    // const loadBalance = async () => {
    //     const result = await api.get("/api/account/balance");
    //     setBalance( result?.data?.balance)
    // }

    // const LogoutButton = () => {
    //     const handleLogout = () => {
    //         instance.logout();
    //     };
    //     return <button onClick={handleLogout}  className="button">Logout</button>;
    // }

    const handleProjectChange = (event) => {
        const selectedPrj = projectList.find(p => p.id === event.target.value);
        dispatch(setCurrentChatId(null));
        dispatch(setCurrentProject(selectedPrj));
    };


    // const projectSwitcher =
    //     <select value={currentProject? currentProject.id: ''} onChange={handleProjectChange} className={styles["project-switcher"]}>
    //         <option disabled  value="" className={styles["project-switcher-option"]}>Select a workspace</option>
    //         {(projectList || []).map(project => (
    //             <option key={project.id} value={project.id} className={styles["project-switcher-option"]}>
    //                 {project.name}
    //             </option>
    //         ))}
    //     </select>


    // const projectDiv = (
    //     <div className={styles["workspace-container"]}>
    //         <div className={styles["workspace-select-container"]}>
    //             {projectSwitcher}
    //         </div>
    //     </div>
    // )

    // const medals = {
    //     "dark": [
    //         "/icons8-gold-medal-40.png",
    //         "/icons8-silver-medal-40.png",
    //         "/icons8-bronze-medal-40.png",
    //         "/icons8-circled-4-50--dark.png",
    //         "/icons8-circled-5-50--dark.png",
    //     ],
    //     "light": [
    //         "/icons8-gold-medal-40.png",
    //         "/icons8-silver-medal-40.png",
    //         "/icons8-bronze-medal-40.png",
    //         "/icons8-circled-4-50--light.png",
    //         "/icons8-circled-5-50--light.png",
    //     ]
    // }


    // const rankingDiv = ranking && (
    //     <div>
    //         <h4>This month's usage ranking</h4>
    //         <div className={styles['ranking-content']}>
    //             {ranking.map((r, index) => (
    //                 <div key={index} className={styles['ranking-line']}>
    //                     <div>
    //                         {index < 3 &&
    //                             <img className={styles["medal-icon"]} src={medals[theme][index]}/>}
    //                         {index >= 3 && <div className={styles["medal-number"]}>{index + 1}</div>}
    //                     </div>
    //                     <div title={r.name}>{r.name}</div>
    //                     <div>{r.count}</div>
    //                 </div>
    //             ))}
    //         </div>
    //     </div>
    // )



    const [showProjectSwitch, setShowProjectSwitch]= useState(null);

    const projectSwitchModal = !!showProjectSwitch && (
        <ContextModal
            clickPosition ={showProjectSwitch}
            handleClose={() => setShowProjectSwitch(null)} closeLabel={"Close"}>
            <div className={styles["title-with-icon"]}>
                {/*<h3>Projects</h3>*/}
                <div className="fa-icon"
                    onClick={() => {
                        navigate('/workspace')
                    }}>
                        <FaEdit/>
                        {/*<img src={theme == "dark" ? "/icons8-edit-50-dark.png" : "/icons8-edit-50-light.png"}*/}
                        {/*     alt="Edit"/>*/}
                    {/*</a>*/}
                </div>
            </div>
            <div className={styles["modal-list-container-scroll"]}>
                {(projectList || []).map((d, index) => (
                    <div className={"selection-list-item"} key={index} onClick={() => {
                        dispatch(setCurrentProject(d));
                        dispatch(setCurrentChatId(null));
                        setShowProjectSwitch(null);
                    }}>
                        <div className={styles['modal-list-title']}>{d.name}</div>
                    </div>
                ))}
            </div>
        </ContextModal>
    )




    const leftNav = (
        <div className={styles["nav-top-container"]}>
            <div className={styles["tab-nav-project-parent"]}>
                <div className={styles["tab-nav-project"]} onClick={(e)=> {
                    const rect = e.target.getBoundingClientRect();
                    setShowProjectSwitch({top:rect.top, left:rect.left});
                }}>
                    {currentProject?.name}
                    {/*<div>*/}
                    {/*<div className={"fa-icon"}>*/}
                    <GoTriangleDown size={20}/>
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*<img src ={theme == "dark"? "/icons8-sort-down-50--dark.png": "/icons8-sort-down-50--light.png"}/>*/}
                </div>
            </div>
            {/*{projectDiv}*/}
            {/*<div>*/}
            {/*    <button onClick={async () => {*/}
            {/*        await dispatch(setCurrentChatId(null))*/}
            {/*        navigate(`/chat`)*/}
            {/*    }}*/}
            {/*            className="button-with-icon button"*/}
            {/*    >*/}
            {/*        <img*/}
            {/*            src={theme == "dark" ? "/icons8-add-50-dark.png" : "/icons8-add-50-dark.png"} //dark for both*/}
            {/*            alt="new chat"*/}
            {/*        />*/}
            {/*        New Chat*/}
            {/*    </button>*/}
            {/*</div>*/}
            <div className={styles["tab-nav-middle"]}>
                <ChatHistory/>
            </div>
            {/*<div className={styles["tab-nav-bottom"]}>*/}
            {/*    /!*{projectDiv}*!/*/}
            {/*    /!*<div className={styles["profile-container"]}>*!/*/}
            {/*    /!*    <NavLink to="/profile">Profile</NavLink>   /!* Display the name of the logged in user *!/*!/*/}
            {/*    /!*    <div className="pointer-icon"*!/*/}
            {/*    /!*         onClick={switchTheme}>*!/*/}
            {/*    /!*        <img src ={theme == "dark"? "/icons8-day-and-night-50-dark.png": "/icons8-day-and-night-50-light.png"} title="dark/light mode"/>*!/*/}
            {/*    /!*    </div>*!/*/}
            {/*    /!*</div>*!/*/}
            {/*    /!*<div className={styles["logout-panel"]}>*!/*/}
            {/*    /!*    <div>{account?.name}</div>*!/*/}
            {/*        /!*<div className="pointer-icon-small"*!/*/}
            {/*        /!*     onClick={() => {*!/*/}
            {/*        /!*         instance.logout()*!/*/}
            {/*        /!*     }}>*!/*/}
            {/*        /!*    <img src ={theme == "dark"? "/icons8-logout-50-dark.png": "/icons8-logout-50-light.png"} title="logout"/>*!/*/}
            {/*        /!*</div>*!/*/}
            {/*    /!*</div>*!/*/}
            {/*    /!*{balance && <div className={styles["balance-div"]}>Current usage: <span className={styles["balance"]}>${Math.trunc(balance*100)/100}</span></div>}*!/*/}
            {/*    /!*<Ranking/>*!/*/}
            {/*</div>*/}
        </div>
    )

    return (
        <>
            {showNav && isMobile &&  <div className={styles["left-container-overlay"]} onClick={() => dispatch(setShowNav(false))}></div>}
            {showNav && (
                <div className={`${styles["left-container-top"]}`} >
                    {leftNav}
                </div>
            )}

            <div className={styles["right-container-top"]}>
                <Routes className={styles["tab-routes"]}>
                    {/*<Route path="/workspace" element={<Project />} />*/}
                    {currentProject && (
                        <>
                            <Route path="/chat/*" element={<Chat />} />
                            <Route path="/context_artifact" element={<ContextArtifact />} />
                            <Route path="/system_message" element={<SystemMessage />} />
                            <Route path="/system_message_edit" element={<SystemMessageEditorPage />} />
                        </>
                    )}
                    <Route path="/profile" element={<Profile />} />
                    <Route path="*" element={<Chat />} />
                </Routes>
            </div>
            {projectSwitchModal}
        </>
    );
}

export default AppRouter;

