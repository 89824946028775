import React, {useContext, useEffect, useRef, useState} from "react";
import {AppContext} from "../../redux/AppContext";
import styles from './TopMostNavBar.module.css'
import {useMsal} from "@azure/msal-react";
import {ThemeContext} from "../../redux/ThemeContext";
import {useNavigate} from "react-router-dom";
import {setChatLayout, setCurrentUsage, setDisableFromat, setInfoMessage} from "../../redux/actions";
// import {getApi} from "../../services/apiService";
import ContextModal from "../ContextModal/ContextModal";
import MaxModal from "../MaxModal/MaxModal";
import Busy from "../Busy/Busy";
import AssistantWrapper from "../AssistantWrapper/AssistantWrapper";
import {useApi} from "../../hooks/useApi";
import {useAuth} from "../../context/AuthContext";
import {
    FaUserTie,
    FaComments,
    FaCogs,
    FaFileAlt,
    FaSun,
    FaMoon,
    FaColumns,
    FaThLarge,
    FaChartBar,
    FaEllipsisH,
    FaQuestionCircle,
    FaSignOutAlt,
    FaCode
} from 'react-icons/fa';
import {IoFolderSharp, IoSettingsOutline} from "react-icons/io5";
import {FaDisplay} from "react-icons/fa6";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {MdCode, MdCodeOff, MdFormatListBulleted, MdOutlineCode} from "react-icons/md";
import FontSizeControl from "./FontSizeControl";


function TopMostNavBar() {
    // const { instance, accounts, inProgress } = useMsal();
    const { theme, switchTheme } = useContext(ThemeContext);
    const navigate = useNavigate();
    const { state, dispatch } = useContext(AppContext);
    const { chatLayout, currentUsage, profile, isDisableFormat, waiting } = state;
    // const fileInputRef = useRef(null);
    const {logout} = useAuth();

    const api = useApi();

    useEffect(()=> {
        const loadMetric = () => {
            api.get('/api/metrics/monthly_usage/current')
                .then(response => {
                    dispatch(setCurrentUsage(response.data));
                })
                .catch((error)=> { /*error handled in apiService*/});
        }
        loadMetric()
    }, [])

    const rotateLayout = () => {
        dispatch(setChatLayout(chatLayout==="bottom"?"side":"bottom"));
    }

    const [showHelpArgs, setShowHelpArgs] = useState(null);
    // const [showMoreArgs, setShowMoreArgs] = useState(null);
    // const [showSuggestionModal, setShowSuggestionModal] = useState(false);
    const [loading, setLoading] = useState(false);


    const helpModal = !!showHelpArgs && (
        <ContextModal
            showClose={false}
            clickPosition={showHelpArgs.pos}
            handleClose={() => setShowHelpArgs(null)}>
            <div className={styles["help-menu-container"]}>
                <div className={"selection-list-item"}
                     onClick={() => {
                         navigate('/feedback')
                         setShowHelpArgs(null)
                     }}>
                    <div>Feedback</div>
                </div>
                <div className={"selection-list-item"}
                    onClick = {()=> {
                        window.open('/updates.html', '_blank');
                        setShowHelpArgs(null)
                    }}
                >
                    <div>Updates</div>
                </div>
            </div>

        </ContextModal>
    )

    // const moreModal = !!showMoreArgs && (
    //     <ContextModal
    //         // showClose={false}
    //         nonblocking={true}
    //         clickPosition={showMoreArgs.pos}
    //         handleClose={() => setShowMoreArgs(null)}>
    //         <div className={styles["more-menu-container"]}>
    //             <div className={"selection-list-item"}
    //                  onClick={() => {
    //                      navigate('/settings')
    //                      setShowMoreArgs(null)
    //                  }}>
    //                 <div>Settings</div>
    //             </div>
    //             <div className={`selection-list-item ${styles["row-panel"]}`}
    //                  onClick={()=> {
    //                      dispatch(setDisableFromat(!isDisableFormat))
    //                  }}
    //             >
    //                 <input type="checkbox"
    //                        checked={isDisableFormat}
    //                        onChange={(e)=> {
    //                            dispatch(setDisableFromat(e.target.checked))
    //                        }}
    //                 />Disable markdown formating
    //             </div>
    //         </div>
    //
    //     </ContextModal>
    // )


    return (
        <div className={styles["container"]}>
            <div className={styles["top"]}>
                <div className={`${styles["logo"]}`}
                     onClick={() => {
                         navigate('/chat')
                     }}>
                    <img
                        src="/android-chrome-512x512.png"
                        alt="App Logo"
                        className={`${styles["app-logo"]} ${waiting?'fade-anim':''}`}
                    />
                </div>
                {(profile?.permissions || []).includes('manager') &&
                    <div className={"fa-icon -larger -color-blue"}
                         onClick={() => {
                             navigate('/manager')
                         }}>
                        <FaUserTie title="Manager"/>
                        {/*<img src={theme == "dark" ? "/icons8-accounting-50--dark.png" : "/icons8-accounting-50--light.png"}*/}
                        {/*     title="Manager"/>*/}
                    </div>}
                <div className={"fa-icon -larger"}
                     onClick={() => {
                         navigate('/chat')
                     }}>
                    <FaComments title="Chat"/>
                    {/*<img src={theme == "dark" ? "/icons8-chat-50--dark.png" : "/icons8-chat-50--light.png"}*/}
                    {/*     title="Chat"/>*/}
                </div>
                <div className={"fa-icon -larger"}
                     onClick={() => {
                         navigate('/system_message')
                     }}>
                    <FaDisplay title="System message"/>
                    {/*<img src={theme == "dark" ? "/icons8-system-50--dark.png" : "/icons8-system-50--light.png"}*/}
                    {/*     title="System message"/>*/}
                </div>
                <div className={"fa-icon -larger"}
                     onClick={() => {
                         navigate('/context_artifact')
                     }}>
                    <FaFileAlt title="Predefined context"/>
                    {/*<img src={theme == "dark" ? "/icons8-document-50-dark.png" : "/icons8-document-50-light.png"}*/}
                    {/*     title="Predefined context" alt={"Chat"}/>*/}
                </div>
                {/*<div className={styles["icon"]}>*/}
                {/*    <div className={styles["placeholder"]}></div>*/}
                {/*</div>*/}
                {/*<div className={styles["icon"]}>*/}
                {/*    <div className={styles["placeholder"]}></div>*/}
                {/*</div>*/}
                {/*<div className={styles["icon"]}>*/}
                {/*    <div className={styles["placeholder"]}></div>*/}
                {/*</div>*/}
                {/*<div className={styles["icon"]}>*/}
                {/*    <div className={styles["placeholder"]}></div>*/}
                {/*</div>*/}
            </div>
            <div className={styles["bottom"]}>
                {/*<div className={styles["icon"]}>*/}
                {/*    <div className={styles["placeholder"]}></div>*/}
                {/*</div>*/}
                {/*<div className={styles["icon"]}>*/}
                {/*    <div className={styles["placeholder"]}></div>*/}
                {/*</div>*/}
                <div className={"fa-icon -larger"}
                     onClick={switchTheme}>
                    {theme === "dark" ? <FaSun title="Light mode"/> : <FaMoon title="Dark mode"/>}
                    {/*<img*/}
                    {/*    src={theme == "dark" ? "/icons8-light-mode-78.png" : "/icons8-dark-mode-50.png"}*/}
                    {/*    title="dark/light mode"/>*/}
                </div>
                <div className={"fa-icon -larger"}
                     onClick={rotateLayout}>
                    {theme === "dark" ? (chatLayout === "bottom" ? <FaColumns title="Side layout"/> :
                            <FaThLarge title="Bottom layout"/>)
                        : (chatLayout === "bottom" ? <FaColumns title="Side layout"/> :
                            <FaThLarge title="Bottom layout"/>)}

                    {/*<img*/}
                    {/*    src={theme == "dark" ? (chatLayout === "column" ? "/icons8-column-pane-50--dark.png" : "icons8-row-pane-50--dark.png")*/}
                    {/*        : (chatLayout === "column" ? "/icons8-column-pane-50--light.png" : "icons8-row-pane-50--light.png")}*/}
                    {/*    title="rotate layout"/>*/}
                </div>
                {/*<div className={styles["icon"]}*/}
                <div className={"fa-icon -larger"}
                     title="Workspace"
                     onClick={() => {
                         navigate('/workspace')
                     }}>
                    <IoFolderSharp/>
                    {/*<img*/}
                    {/*    src={theme == "dark" ? "/icons8-organization-50--dark.png" : "/icons8-organization-50--light.png"}*/}
                    {/*    title="worskspace"/>*/}
                </div>
                <div className={`${styles["current-usage-count-container"]}`}>
                    <div className={"fa-icon -larger"}
                         title="Metrics"
                         onClick={() => {
                             navigate('/metrics')
                         }}>
                        <FaChartBar/>
                    </div>
                    {/*<img src={theme == "dark" ? "/icons8-ranking-50--dark.png" : "/icons8-ranking-50--light.png"}*/}
                    {/*     title="metrics"/>*/}
                    <div className={styles["current-usage-count"]}>{currentUsage}</div>
                </div>
                <div className={"fa-icon -larger"}
                     title={isDisableFormat? "Enable format": "Disable format"}
                     onClick={() => dispatch(setDisableFromat(!isDisableFormat))}>
                    {isDisableFormat ? <MdCode/> : <MdCodeOff />}
                </div>

                <div className={"fa-icon -larger"}
                     onClick={() => {
                         navigate('/settings')
                     }}>
                    <IoSettingsOutline/>
                </div>
                {/*<div className={"fa-icon -larger"}*/}
                {/*     title="Help"*/}
                {/*     onClick={(event) => {*/}
                {/*         const rect = event.target.getBoundingClientRect();*/}
                {/*         setShowHelpArgs({pos: {top: rect.bottom, left: rect.right}});*/}
                {/*     }}>*/}
                {/*    <FaQuestionCircle/>*/}
                {/*</div>*/}
                <div className={"fa-icon -larger"}
                     title="Logout"
                     onClick={() => {
                         if (window.confirm("Are you sure you want to logout?")) {
                             logout();
                         }
                     }}>
                    <FaSignOutAlt/>
                    {/*<img src={theme == "dark" ? "/icons8-logout-50-dark.png" : "/icons8-logout-50-light.png"}*/}
                    {/*     title="logout" alt={"logout"}/>*/}
                </div>
            </div>
            {helpModal}
            {loading && <Busy/>}
        </div>
    )
}

export default TopMostNavBar;
